<script setup lang="ts">
// Properties and events.
//
const emit = defineEmits(['process:list-event'])
const props = defineProps<{
  cellId?: string
  cellIndex?: number
  listType?: string
  listButtonText?: string
  listButtonIcon?: string
}>()
// Main variables.
//
const cellId = toRef(props, 'cellId')
const cellIndex = toRef(props, 'cellIndex')
const listType = toRef(props, 'listType')
const listButtonText = toRef(props, 'listButtonText')
const listButtonIcon = toRef(props, 'listButtonIcon')
// Functions.
//
const processListAction = function processListAction(id: string) {
  emit('process:list-event', id)
}
</script>

<template>
  <td class="expand-icon text-end">
    <div v-if="listType" class="d-flex justify-content-end">
      <Btn
        :id="`customButton-${cellIndex}`"
        style-opts="rk-btn--light rk-btn--text-icon d-none d-lg-flex"
        @process:click="processListAction(cellId)"
      >
        <i
          class="rk-icon rk-icon--text-sm fontello me-2"
          :class="[listButtonIcon]"
        ></i>
        {{ listButtonText }}
      </Btn>
      <i
        class="rk-icon rk-icon--text-sm fontello icon-chevron-down d-inline-block d-lg-none"
      ></i>
    </div>
    <div v-else>
      <i
        class="rk-icon rk-icon--text-sm fontello icon-chevron-down d-inline-block d-lg-none"
      ></i>
      <i
        class="rk-icon rk-icon--red rk-icon--text-sm rk-nav-icon fontello icon-sys-edit d-none d-lg-inline-block"
      ></i>
    </div>
  </td>
</template>

<style lang="scss"></style>
