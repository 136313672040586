<template>
  <div class="py-3">
    <div class="table-loading">
      <div class="table-loading__header"></div>
      <div class="table-loading__spinner">
        <span
          class="spinner-grow rk-icon rk-icon--primary"
          role="status"
        ></span>
        <h3 class="mt-2">Refreshing Data</h3>
      </div>
      <hr class="rk-line-break rk-line-break--default mt-5 mb-4" />
      <hr class="rk-line-break rk-line-break--default my-4" />
      <hr class="rk-line-break rk-line-break--default my-4" />
      <hr class="rk-line-break rk-line-break--default my-4" />
      <hr class="rk-line-break rk-line-break--default my-4" />
    </div>
  </div>
</template>

<style lang="scss"></style>
